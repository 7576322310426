import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout.js"
import Description from "../components/description.js"
import DeepDive from "../components/deep-dive.js"
import Lightbox from "../components/lightbox-modal.js"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

class ProjectTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.handleDeepDiveToggle = this.handleDeepDiveToggle.bind(this);
        this.handleThumbnailSelect = this.handleThumbnailSelect.bind(this);
        this.handleLightboxClose = this.handleLightboxClose.bind(this);
        this.handleLightboxControl = this.handleLightboxControl.bind(this);
        this.defaultDeepDiveButtonText = "Wanna read more?";
        this.altDeepDiveButtonText = "On second thought, let's skip this part";
        this.state = {
            toggleButtonText: this.defaultDeepDiveButtonText,
            showDeepDive: false,
            showLightbox: false,
            selectedLightboxIndex: 0
        };
    }

    handleDeepDiveToggle(e) {
        let buttonText = (this.state.toggleButtonText === this.defaultDeepDiveButtonText ? this.altDeepDiveButtonText : this.defaultDeepDiveButtonText);
        this.setState({ showDeepDive: (!this.state.showDeepDive) });
        this.setState({ toggleButtonText: buttonText });
        if (!this.state.showDeepDive) {
            let deepDiveElement = document.querySelector(".showcase-page__deep-dive");
            if (deepDiveElement !== null) {
                deepDiveElement.scrollIntoView();
            }
        }
    }

    handleThumbnailSelect(e, selectedIndex = 0) {
        this.setState({ selectedLightboxIndex: selectedIndex, showLightbox: true });
    }
    
    handleLightboxClose() {
        this.setState({ showLightbox: false, selectedLightboxIndex: 0 });
    }

    handleLightboxControl(selectedIndex) {
        this.setState({ selectedLightboxIndex: selectedIndex });
    }

    render() {
        const { data } = this.props;
        return (
            <Layout pageTitle={data.project.title}>
                {/* <h1>{data.project.title}</h1> */}
                {/* <div className="showcase-page__description" dangerouslySetInnerHTML={{ __html: data.project.description.html }}></div> */}
                <Description className="showcase-page__description" html={data.project.description.html} />
                {data.project.deep_dive !== null &&
                    <div className="showcase-page__deep-dive">
                        <button className={`showcase-page__deep-dive__button ${(this.state.showDeepDive ? "read-less" : "read-more")}`} onClick={(e) => this.handleDeepDiveToggle(e)}>
                            {this.state.toggleButtonText}
                            <FontAwesomeIcon className="fa-icon" aria-hidden="true" icon={this.state.showDeepDive ? ["far", "times-circle"] : ["far", "arrow-alt-circle-right"]} />
                        </button>
                        <DeepDive className="showcase-page__deep-dive__description bg-light px-4 pt-3 pb-1" html={data.project.deep_dive.html} show={this.state.showDeepDive} />
                    </div>
                }
                {data.project.relationships.media_graphic.length > 0 &&
                    <div className="showcase-page__thumbnail-wrapper">
                        <h2>Examples:</h2>
                        <p>Click on a thumbnail for a larger view:</p>
                        {data.project.relationships.media_graphic.map((image, index) => (
                            <button className="showcase-page__thumbnail" key={index} onClick={(e) => this.handleThumbnailSelect(e, index)}>
                                <img className={(image.media_width > image.media_image.height) ? 'img-landscape' : 'img-portrait'} src={image.relationships.media_image_file.file.url} alt={image.media_image.alt} />
                            </button>
                        ))}
                    </div>
                }
                {data.project.links.length > 0 &&
                    <div className="showcase-page__related-links">
                        <h2>Related links:</h2>
                        <ul>
                            {data.project.links.map((link, index) => (
                            <li key={index}>
                                <a href={link.uri} title={link.title}>{link.title}</a>
                            </li>
                            ))}
                        </ul>
                    </div>
                }
                <Lightbox lightboxSrc={
                    data.project.relationships.media_graphic.map((image) => {
                        return {
                            alt: image.media_image.alt,
                            width: image.media_image.width,
                            height: image.media_image.height,
                            caption: image.media_caption,
                            src: image.relationships.media_image_file.file.url,
                        }
                    })}
                    activeIndex={this.state.selectedLightboxIndex}
                    show={this.state.showLightbox}
                    handleThumbnailSelect={this.handleThumbnailSelect}
                    handleLightboxClose={this.handleLightboxClose}
                    handleLightboxControl={this.handleLightboxControl}
                />
            </Layout>
        )
    }
}

// const ProjectTemplate = ({ data }) => (
//     <Layout>
//         <h1>{data.project.title}</h1>
//         <div dangerouslySetInnerHTML={{ __html: data.project.description.html }}></div>
//         <div>
//             {data.project.relationships.media_graphic.map((image, index) => (
//                 <div key={index}>
//                     <img src={image.relationships.media_image_file.file.url} alt={image.media_image.alt} />
//                 </div>
//             ))}
//         </div>
//         <p><strong>Related links:</strong></p>
//         <ul>
//             {data.project.links.map((link, index) => (
//             <li key={index}>
//                 <a href={link.uri} title={link.title}>{link.title}</a>
//             </li>
//             ))}
//         </ul>
//     </Layout>
// )

export default ProjectTemplate

export const query = graphql`
    query($slug: String!) {
        project: nodeProject(fields: { slug: { eq: $slug } }) {
            title
            description: field_description_formatted {
                html: processed
            }
            deep_dive: field_deep_dive_formatted {
                html: processed
            }
            links: field_related_links {
                title
                uri
            }
            relationships {
                media_graphic: field_media_photo_graphic {
                    media_image: field_media_image {
                        alt
                        height
                        title
                        width
                    }
                    media_caption: field_media_image_caption
                    relationships {
                        media_image_file: field_media_image {
                            file: localFile {
                                url: publicURL
                            }
                        }
                    }
                }
            }
        }
    }
`